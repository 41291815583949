/*******
NEED TO A TABLE OF CONTENTS


/************
  GENERAL
*************/



@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight:400;
    src: url('/assets/fonts/PTSerif-Regular.ttf');
  }
  @font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight:700;
    src: url('/assets/fonts/PTSerif-Bold.ttf');
  }
  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight:400;
    src: url('/assets/fonts/Inter-Regular.ttf');
  }
  @font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight:700;
    src: url('/assets/fonts/Inter-Bold.ttf');
  }






body {
    margin: 0;
    font-family: 'PT Serif', serif;
    overflow-x: hidden;
}

h1,
h1.pagetitle {
    font-size: 2.8vw !important;
}

h2 {
    color: #961b27;
    font-size: 1.6vw;
}

article h2 a {
    color: black;
    text-decoration: none;
}

h3 {
    font-size: 1.2vw;
}

a {color:#961b27;transition: .3s;}

a:hover {color:#000;}

p,
ul,
.attorneyProfileContactInformation {
    font-size: 1.1vw;
}

.attorneyProfilePhoto {
    height: 12vw;
}

.social-shareon-text {
    font-size: 1.1vw;
}

.social-share-svg {
    height: 1vw;
    padding-right: 1vw;
    color: black;
    fill: currentColor;
}

.social-share-svg:hover {
    color: #be1e2d;
    fill: currentColor;
}

.social-share-text {
    display: none;
}

section#comments {
    display: none;
}

.sidebar {
    display: inline-block;
    width: 18%;
    padding-top: 4.2vw;
    padding-left: 10vw;
    padding-right: 11vw;
    padding-bottom:5%;
}

.blog .sidebar {
    padding-top: 1.2vw;
}

.sidebar .title {
    font-size: 1.6vw;
    font-weight: 400;
    border-top: 1px solid #e6e6e6;
    padding-top: 1vw;
    margin-top: 1.8vw;
    padding-bottom:1vw;
}


.sidebar a {
    margin-bottom: .3vw;
}

.sidebar a {
    color: black;
    text-decoration: none;
    display: block;
	font-size: 1.1vw;
}

.sidebar a:hover {
    color: #961b27;
}
.sidebar .dropdown{
    padding-left:.5vw;
}

.column-main {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin: 2vw 0;
}

article a {
    color: #be1e2d;
    text-decoration: none;
}

/*********
 for blog index
**********/
body.blog footer {
    margin-top: 0;
}

.postMeta.post-meta {
    margin-top: 0;
}

header.header-post {
    z-index: 0;
}

.featured-posts-container {
    max-width: 80vw;
    margin: 0 auto;
}

.featured-posts-container .featured {
    min-height: 19vw;
}

.featured-posts-container .byline {
    display: none;
}

.featured-posts-container .featured .post {
    max-width: 100%;
    display: block;
}

.featured-posts-container .featured .post img {
    float: left;
    padding-right: 3vw;
    width: 30vw;
}

.featured .post header {
    position: inherit;
}

.featured-posts-container .post {
    max-width: 18vw;
    padding-right: 1.5vw;
    display: inline-block;
    vertical-align: top;
}

.featured-posts-container .post img {
    width: 19vw;
}

.featured-posts-container .post .byline {
    display: none;
}

.sidebar ul {
    padding-left: 0;
}

.featured-posts-container .column-main .header-post {
    float: left;
    width: 30%;
    padding-right: 5%;
}

article footer {
    margin-top: 0;
}

article.post {
    margin-bottom: 4vw;
}

/**********
  header
***********/
header {
    position: relative;
    z-index: 1;
}

header>div.header-top-row {
    background: black;
    color: white;
    display: block;
    text-align: right;
    font-size: 1.6vw;
    text-decoration: none;
    padding: 0.5vw 3vw .5vw 0;
    height: 2vw;
}

div.header-top-row a {
    color: white;
    text-decoration: none;
}

.header-top-row svg {
    color: white;
    fill: currentColor;
}

.header-top-row a:hover svg {
    color: #d74552;
}

header>div {
    background: rgba(0, 0, 0, .2);
    height: 8vw;
    border-bottom: 2px solid black;
    background-color: #fff;
}

/*this is the logo*/
header img {
    height: 6vw;
    padding: 1vw 1vw;
}

header nav a {
    font-size: 1.4vw;
    color: white;
    text-decoration: none;
    padding: 0 .8vw;
    transition: all .3s ease;
    color: black;
}

header nav a:hover {
    color: red;
}

header nav {
    display: inline-block;
    float: right;
    padding-top: 3vw;
}

header nav span {
    padding-bottom: 3vw;
}

header nav span:hover>div {
    display: block;
    margin-top: 2vw;
    opacity: 1;
    animation-name: top-nav-menu;
    animation-duration: .3s;
}

@keyframes top-nav-menu {
    0% {
        opacity: 0;
        margin-top: 7vw;
    }

    100% {
        opacity: 1;
        margin-top: 2vw;
    }
}

header nav span>div {
    position: absolute;
    background: white;
    border-radius: 10px;
    margin-top: 7vw;
    padding: 1vw;
    opacity: 0;
    display: none;
    ;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.about-menu {
    height: 28vw;
    width: 48vw;
    margin-left: -6vw;
}

.about-menu::before {
    content: "";
    display: block;
    height: 1vw;
    width: 1vw;
    background-color: white;
    position: relative;
    bottom: 1.5vw;
    left: 15.6vw;
    transform: rotateZ(45deg);
}

.about-menu a {
    color: black;
    font-size: 1.1vw;
}

.about-menu p {
    margin: 0;
}

.about-menu .left {
    display: inline-block;
    width: 33%;
    height: auto;
    margin-top: -1vw;
}

.about-menu .title {
    font-size: 1.6vw;
}

.about-menu .subtitle {
    font-size: 1vw;
}

.about-menu hr {
    position: absolute;
    height: 0px;
    width: 45vw;
    border: 1px solid #e6e6e6;
}

.about-menu img {
    height: auto;
    width: 13vw;
    padding: 0;
    margin-top: 1.5vw;
    margin-bottom: .6vw;
}

.about-menu .social-media a {
    color: black;
    padding: 0 1.5vw;
}

.about-menu .social-media img {
    height: 1vw;
    width: auto;
    margin-top: 0vw;
}

.about-menu .right {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin-top: 6vw;
}

.about-menu .right p a {
    padding: 0;
}

.about-menu .right .button {
    border: 2px solid #d74552;
    position: relative;
    top: 2vw;
}

.practice-area-menu {
    width: 83vw;
    margin-left: -37vw;
}

.practice-area-menu::before {
    content: "";
    display: block;
    height: 1vw;
    width: 1vw;
    background-color: white;
    position: relative;
    bottom: 1.5vw;
    left: 59vw;
    transform: rotateZ(45deg);
}


.practice-area-menu .column.one {
    display: inline-block;
    vertical-align: top;
    width: 59%;
}

.practice-area-menu .column.one ul {
    width: 35%;
    display: inline-block;
    vertical-align: top;
    padding-left: 0;
    margin-right: 1vw;

}

.practice-area-menu .column.two {
    display: inline-block;
    vertical-align: top;
    width: 37%;
    padding-left: 3%;
}

.practice-area-menu .column.one img {
    height: auto;
    width: 10vw;
    float: left;
    padding-top: 0;

}

.practice-area-menu .column.two img {
    height: auto;
    width: 10vw;
    float: left;
    padding-top: 0;

}

.practice-area-menu .title a {
    font-size: 1.5vw;
    color: black;
    display: block;
    padding: 0;
}

.practice-area-menu .title a:hover {
    color: red;
}

.practice-area-menu a {
    color: red;
    font-size: .87vw;
    line-height: 2.2vw;
}

.practice-area-menu ul {
    list-style: none;
}

/****************
   MOBILE NAV
****************/
nav .mobile-header{display:none;}
header.mobile-header {
    display: none;
    margin-bottom: 0px;
    position: fixed;
    width: 100%;
    top: 0vw;
    z-index: 9;
    background: white;
    height: 16vw;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mobile-nav .logo-left a {
    border-bottom: 0px;
    margin-top: 0;
}

section#logo img {
    width: 40vw;
    height: auto;
    position: relative;
    bottom: 1vw;
}

.pull-right {
    display: inline-block;
    float: right;
    width: 25vw;
    height: 7vw;
}

.phone {
    display: inline-block;
    height: 10vw;
    width: 7vw;
    width: 12vw;
    vertical-align: top;
}

.mobile-nav .phone a {
    margin-top: .8vw;
    padding-top: 0;
    border-bottom: 0px;
}

.phone i {
    font-size: 52px;
    color: black;
    font-size: 52px;
    color: black;
    padding: 12px 0 0 53px;
}

.mobile-nav-btn {
    display: inline-block;
    width: 10vw;
    height: 10vw;
}

div.mobile-nav-btn div.nav-dot {
    height: 1.5vw;
    width: 1.5vw;
    border-radius: 4px;
    background-color: #ad2136;
    margin: .6vw auto;
    opacity: 1;
    transition: opacity .3s ease;
}

div.mobile-nav-btn div.nav-dot:first-child {
    margin: 2vw auto 0;
}

div.mobile-nav-btn img {
    width: 6vw;
    position: absolute;
    top: 2vw;
    right: 4vw;
    opacity: 0;
    transition: opacity .3s ease;
}

.logo-left {
    display: inline-block;
    height: 12vw;
}

#logo {
    height: 100%;
}


div.mobile-nav-btn:hover div.mobile-nav {
    display: block;
}

div.mobile-nav {
    display: block;
    vertical-align: top;
    position: absolute;
    background: white;
    padding: 0px;
    right: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 98;
    overflow: scroll;
    height: auto;
    overflow: scroll;
    transition: top .3s ease;
    width: 44%;
    top: -115vw;
    max-height: 90vh;
}

div.mobile-nav ul {
    list-style: none;
    padding: 0 30px;
}

div.mobile-nav ul li {
    margin: 20px auto;
    font-size: 17px;
}

div.mobile-nav ul li a {
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
    display: inline-block;
    padding-bottom: 9px;
}

.mobile-nav a {
    font-size: 3vw;
    text-decoration: none;
    color: #144190;
    display: block;
    margin: 0vw 3.5vw;
    padding: 2vw 0 0vw;
    border-bottom: 1px solid #e1e1e1;
}

.mobile-nav .mobile-nav a {
    color: #ad2136;
    padding: 4vw 0 4vw;
}

header nav.mobile-nav {
    height: 13vw;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 9;
    /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
}

.mobile-nav-practice-areas-menu {
    display: none;
}

div.mobile-nav-screen {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

header>div.sidebar-nav {
    position: relative;
    width: 95%;
    background-color: #ad2136;
    color: white;
    height: auto;
    max-height: 83vh;
    top: -90vh;
    z-index: 5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-bottom: 0;
    transition: top .3s ease;
    padding-left: 5%;
    overflow: scroll;
}

header>div.button {
    position: fixed;
    top: 16vw;
    z-index: 8;
    width: 100%;
    padding: 2vw;
    text-align: center;
    background-color: #ad2136;
    color: white;
    font-size: 3.7vw;
    height: auto;
    border-bottom: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media (max-width:850px) {
    header {
        display: none;
    }

    header.mobile-header {
        display: block;
    }

    header.header-post {
        display: block;
    }

    body {
        padding-top: 16vw;
    }
}


/************
  SECTION ONE
*************/
.slider-bg {
    background-color: black;
    position: absolute;
    height: 55vw;
    width: 100vw;
    top: 0;
    z-index: -2;
}

.section-one {
    background-image: url('/assets/images/Andres-Desk.png');
    height: 30vw;
    margin-top: -8vw;
    background-size: cover;
    padding-top: 22vw;
    color: white;
    padding-left: 5vw;
    background-size: 110%;
    background-position: center top;
    animation-name: bg-zoom-top;
    animation-duration: 3s;
    opacity: 1;
    transition: opacity .3s ease;
}

@keyframes bg-zoom-top {
    0% {
        background-size: 100%;
    }

    100% {
        background-size: 110%;
    }
}

.section-one .title {
    font-size: 4vw;
    position: relative;
    right: 0vw;
    animation-name: right-slide;
    animation-duration: 1s;
}

@keyframes right-slide {
    0% {
        right: 66vw;
    }

    100% {
        right: 0vw;
    }
}

.section-one img {
    opacity: 1;
    animation-name: s1-img-fade;
    animation-duration: 2.66s;
}

@keyframes s1-img-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.section-one .subtitle {
    font-size: 2.7vw;
    position: relative;
    right: 0vw;
    animation-name: right-slide-sub;
    animation-duration: 1s;
}

@keyframes right-slide-sub {
    0% {
        right: 66vw;
    }

    25% {
        right: 66vw;
    }

    100% {
        right: 0vw;
    }
}

.section-one a {
    color: white;
    text-decoration: none;
    font-size: 2.7vw;
    font-weight: 700;
    border: 3px solid white;
    padding: .7vw 5vw;
    position: relative;
    top: 2.5vw;
    left: 15vw;
    animation-name: right-slide-a;
    animation-duration: 1s;
    transition: all .3s ease;
}

.section-one a:hover {
    color: #961b27;
    background-color: white;
}

@keyframes right-slide-a {
    0% {
        left: -51vw;
    }

    33% {
        left: -51vw;
    }

    100% {
        left: 15vw;
    }
}

/***********
  SECTION TWO
**************/

.section-two>img {
    width: 9vw;
    display: block;
    margin: 0 auto;
    padding-top: 3.5vw;
    padding-right: 1vw;
}

.section-two .title {
    text-align: center;
    font-size: 2.5vw;
}


.section-two a {
    color: black;
}

.section-two .subtitle {
    text-align: center;
    font-size: 1vw;
    padding-top: 1vw;
    padding-bottom: 3vw;
}

.practice-area {
    display: inline-block;
    width: 24%;
    vertical-align: top;
    margin-left: 6.9vw;
    padding: 0 0 2.8vw 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.practice-area:hover {
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.practice-area img {
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 16vw;
}

.practice-area .title {
    font-size: 1.4vw;
    padding-top: 2.3vw;
    text-align: left;
    padding-left: 1.2vw;
}

.practice-area .subtitle {}

.practice-area p {
    font-size: 1.1vw;
    width: 21vw;
    margin: 0 auto;
    padding-top: 1.8vw;
}

/***************
  SECTION three
*****************/
.section-three {
    background-image: url('/assets/images/crane.png');
    background-position: bottom;
    background-repeat: no-repeat;
}

.section-three img {
    width: 9vw;
    display: block;
    margin: 0 auto;
    padding-top: 5vw;
    padding-right: 1vw;
}

.section-three .title {
    font-size: 2.6vw;
    text-align: center;
    display: block;
    padding-bottom: 3vw;
}

.section-three div.column {
    display: inline-block;
    width: 40%;
    vertical-align: top;
    padding: 0 4vw 0 5vw;
}

.section-three h1 {
    text-align: right;
    padding-right: 6vw;
    font-size: 2.4vw;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .1vw;
    margin-bottom: 0;
    padding-bottom: 1vw;
}

.section-three h1 strong {
    color: #961b27;
    font-size: 1.6vw;
    font-weight: 700;
    position: relative;
    bottom: 1vw;
}

/***********
banner
************/
.banner {}

.banner img {
    width: 100%;
}

.banner .title {
    color: rgba(224, 224, 224, 0.5);
    font-size: 14vw;
    text-align: center;
    letter-spacing: 2vw;
    transform: scaleX(1.4);
    position: absolute;
    margin-top: -16vw;
    width: 100%;
}

.banner p {
    background: #5a141c;
    color: white;
    padding: 4vw;
    font-size: 3vw;
    margin-top: -1vw;
}

.banner a {
    color: white;
    text-decoration: none;
    margin-left: 7vw;
    border: 3px solid white;
    padding: .8vw 3vw;
    transition: all .3s ease;
}

.banner a:hover {
    background-color: white;
    color: #961b27;
}

/***********
SECTION FOUR
***********/
.section-four {
    margin-bottom: 4vw;
}

.section-four>img {
    width: 9vw;
    display: block;
    margin: 0 auto;
    padding: 1vw 1vw 0 0;
}

.section-four>.title {
    font-size: 2.6vw;
    text-align: center;
    display: block;
    padding-bottom: 6.3vw;
}

.section-four .column {
    display: inline-block;
    vertical-align: top;
    width: 22%;
    padding-left: 1vw;
}

.section-four .column .title {
    font-size: 1.3vw;
    font-weight: 300;
    margin-bottom: 0vw;
}

.section-four .column.one {
    width: 24vw;
    padding-left: 4vw;
}

.section-four .column.one>img {
    width: 100%;
}

.section-four .column.one div {
    text-align: center;
}

.section-four .column.one a img {
    height: 1vw;
    padding: 1vw 1.1vw;
}

.section-four .column.two a {
    text-decoration: none;
    color: black;
    border: 3px solid #d74552;
    padding: .5vw 1vw;
    position: relative;
    top: 2vw;
    transition: all .3s ease;
}

.section-four .column.two a:hover {
    background-color: #d74552;
    color: white;
}

.section-four .column.three div img {
    width: 5vw;
    float: left;
    margin-right: 1vw;
}

.section-four .column.three .title {
    margin-bottom: 1.5vw;
}

.section-four .column.three div .title {
    font-size: 1vw;
    margin-bottom: 0;
}

.section-four .column.three div p {
    margin: 0 0 1.4vw;
    font-size: 1vw;
    font-weight: 300;
    color: #8a8a8a;
}

.section-four .column.four span {
    float: left;
    margin-bottom: 1.3vw;
    background: #d74552;
    color: white;
    padding: .6vw 1vw;
    font-size: 1vw;
    font-weight: 700;
    margin-top: .4vw;
    position: relative;
    right: 0.5vw;
}

.section-four .column.four p {
    margin-top: 0;
    margin-bottom: 2.9vw;
}

.section-four .column.four .progress-bar {
    height: 1.5vw;
    width: 20vw;
    background-color: #e6e6e6;
    overflow: hidden;
}

.section-four .column.four .progress-bar-fill {
    content: '';
    display: block;
    height: 1.5vw;
    width: 20vw;
    position: relative;
    background-color: #d74552;
    right: 4vw;
}

/*********
banner TWO
************/
.banner-two {
    background-image: url('/assets/images/Books-Image.png');
    background-size: contain;
    height: 31vw;
    background-position: center right;
    background-repeat: no-repeat;
    background-color: black;
}

.banner-two div {
    color: #c2a792;
    height: 84%;
    background: linear-gradient(90deg, black 34%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    font-size: 2vw;
    padding: 5vw 45vw 0vw 4vw;
    line-height: 3vw;
}

.banner-two img {
    position: relative;
    top: 2vw;
    left: 23vw;
}


/*************
testimonials Section
***************/
.testimonials {
    margin-bottom: 6vw;
}

.testimonials>img {
    float: left;
    height: 22vw;
    padding-top: 4vw;
    padding-left: 4vw;
    padding-right: 8vw;
}

.testimonials .scale {
    margin-top: 2vw;
    height: 3vw;
}

.testimonials>div {
    padding-left: 9vw;
    padding-right: 13vw;
    text-align: center;
}

.testimonials>div div {
    font-size: 2.6vw;
}

.testimonials p {
    text-align: left;
    padding-left: 5vw;
    font-size: 1.6vw;
    line-height: 2vw;
    font-style: italic;
}

.testimonials .quoteicon {
    float: left;
}

/***************
footer Section
****************/
footer {
    margin-top: 4vw;
}

.form.container {
    background-image: url('/assets/images/form-background.png');
    height: auto;
    background-position: center;
    background-size: cover;
    padding: 0 18vw;
}

.form.container div.title {
    font-size: 2.6vw;
    text-align: center;
    display: block;
    padding: 0;
    padding-top: 5vw;
    margin: 0 auto;
    color: white;
}

.section-news .scale {
    display: block;
    margin: 5vw auto .5vw;
    height: 3vw;
    position: relative;
    right: .3vw;
}

.section-news div.title {
    font-size: 2.6vw;
    text-align: center;
    display: block;
    padding: 0;
    margin: 0 auto 2vw;
}

.section-news div {
    display: inline-block;
    vertical-align: top;
    width: 23.5%;
    padding-left: 7.5vw;
}

.section-news div img {
    width: 23vw;
    display: block;
}

.section-news a,
.testimonials a {
    text-decoration: none;
    color: black;
    border: 3px solid #d74552;
    padding: .5vw 1vw;
    position: relative;
    top: 2vw;
    transition: all .3s ease;
}

.section-news a{
    display: block;
    text-align: center;
    width: 40%;
    margin-top:1vw;
}

.section-news a:hover,
.testimonials a:hover {
    background-color: #d74552;
    color: white;
}

.section-news>a {
    display: block;
    text-decoration: none;
    color: black;
    border: 2px solid black;
    width: 33vw;
    text-align: center;
    padding: 1vw 0;
    font-size: 1.4vw;
    margin: 7vw auto;
}

.section-news>a:hover {
    background-color: black;
}

footer .contact {
    background-image: url('/assets/images/omaha-skyline-footer.png');
    background-size: cover;
    background-position: center;
    color: white;
    padding: 4vw 0;
}

footer .contact img {
    width: 46vw;
    float: left;
    margin: 1vw 23vw 7vw 4vw;
}

footer .contact b {
    font-size: 1.8vw;
    font-weight: 300;
    display: block;
}

footer .contact a {
    color: white;
    text-decoration: none;
    font-size: 1.6vw;
}

footer .contact p {
    margin: 0;
    font-size: 1.6vw;
}

.copyright {
    padding: 1.5vw 3vw;
    font-size: .8vw;
    background: black;
    color: white;
}

.copyright span {
    float: right;
    margin-right: 3vw;
}

.copyright img{height:1vw;width:auto;}

.copyright a {
    color: white;
}

.copyright a:hover {
    color: #961b27;
}



.social-share-link.social-share-gplus {
    display: none !important;
}

.red-button{
    text-decoration: none;
    color: black;
    border: 3px solid #d74552;
    padding: .5vw 1vw;
    transition: all .3s ease;
    width:20%;
    text-align: center;
}

.red-button:hover{
    cursor: pointer;
    border: 3px solid #000;
}

.titlebar{
    background-image: url('/assets/images/foggy-crane.png');
    height:20vw;
    background-size: 109%;
    background-position-y: -16vw;
    background-position-x: -8vw;
    margin-top:-1.1vw;
}
